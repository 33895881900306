import React, { useEffect, useState } from 'react';
import { Accordion } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { isEqual } from 'lodash';

import { v4 as uuid } from 'uuid';
import videojs from 'video.js';

import Subtitles from './SidebarComponents/Subtitles';
import Watermark from './SidebarComponents/Watermark';
import TimeRange from './SidebarComponents/TimeRange';
import PlayerConfig from './SidebarComponents/PlayerConfig';
import BlockConfig from './SidebarComponents/BlockConfig';

const messages = defineMessages({
  title: {
    id: 'VideoJS',
    defaultMessage: 'VideoJS',
  },
  VideoJS: {
    id: 'VideoJS',
    defaultMessage: 'VideoJS',
  },
  NoVideo: {
    id: 'No Video selected',
    defaultMessage: 'No Video selected',
  },
});

const VideoJSSidebar = (props) => {
  const { data, block, onChangeBlock } = props;
  const intl = useIntl();
  const [currentActiveFieldset, setCurrentActiveFieldset] = useState(0);
  const [key, setKey] = useState(uuid());

  const defaultValues = {
    controls: true,
    height: 400,
    responsive: true,
    playbackRates: [0.5, 0.75, 1, 1.25, 1.5, 2],
    fluid: true,
    ...data.options,
  };

  const videoSourceExist = () => {
    return (
      data.options?.sources?.length &&
      data.options?.sources[0].src &&
      !data.player?.error
    );
  };

  useEffect(() => {
    if (data.options === undefined) {
      onChangeBlock(block, {
        ...data,
        options: {},
      });
    } else {
      Object.keys(defaultValues).forEach((k) => {
        if (!isEqual(defaultValues[k], data.options[k])) {
          onChangeBlock(block, {
            ...data,
            options: {
              ...data.options,
              [k]: defaultValues[k],
            },
          });
          data.options[k] = defaultValues[k];
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.options]);

  function handleCurrentActiveFieldset(e, blockProps) {
    const { index } = blockProps;
    const newIndex = currentActiveFieldset === index ? -1 : index;

    setCurrentActiveFieldset(newIndex);
  }

  return (
    <div className="ui form" key={key}>
      <header className="header pulled">
        <h2>
          {intl.formatMessage(messages.title)} <i>({videojs.VERSION})</i>
        </h2>
      </header>

      <BlockConfig {...props} />
      <Accordion
        fluid
        styled
        className="form"
        key="videojs-configuration-accordion"
      >
        <PlayerConfig
          {...props}
          currentActiveFieldset={currentActiveFieldset}
          handleCurrentActiveFieldset={handleCurrentActiveFieldset}
          refreshSidebar={() => setKey(uuid)}
          videoSourceExist={videoSourceExist}
        />
        {videoSourceExist() && !data.player?.error && (
          <>
            <Subtitles
              {...props}
              disabled={props.type === 'mediapagevideojs'}
              currentActiveFieldset={currentActiveFieldset}
              handleCurrentActiveFieldset={handleCurrentActiveFieldset}
              refreshSidebar={() => setKey(uuid)}
            />
            <Watermark
              {...props}
              currentActiveFieldset={currentActiveFieldset}
              handleCurrentActiveFieldset={handleCurrentActiveFieldset}
            />
            <TimeRange
              {...props}
              currentActiveFieldset={currentActiveFieldset}
              handleCurrentActiveFieldset={handleCurrentActiveFieldset}
            />
          </>
        )}
      </Accordion>
    </div>
  );
};

export default VideoJSSidebar;
