// SemanticUI-free pre-@plone/components
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector, shallowEqual } from 'react-redux';
import { useLocation } from 'react-router';
import { getVar } from '@package/helpers/utils';

const Anontools = () => {
  const token = useSelector((state) => state.userSession.token, shallowEqual);

  const location = useLocation();

  const cameFromPrefix = getVar('RAZZLE_CAME_FROM_PREFIX', '');

  var loginUrl = getVar('RAZZLE_LOGIN_URL', '/login');
  if (cameFromPrefix && location.pathname) {
    const pathName = location.pathname.endsWith('.html')
      ? location.pathname.split('/').slice(0, -1).join('/') || '/'
      : location.pathname;

    loginUrl = loginUrl + '?came_from=' + cameFromPrefix + pathName;
  }

  return (
    !token && (
      <div className="anontools">
        <a aria-label="login" href={loginUrl}>
          <FormattedMessage id="Log in" defaultMessage="Log in" />
        </a>
      </div>
    )
  );
};

export default Anontools;

Anontools.propTypes = {
  token: PropTypes.string,
  content: PropTypes.shape({
    '@id': PropTypes.string,
  }),
};

Anontools.defaultProps = {
  token: null,
  content: {
    '@id': null,
  },
};
