import '@plone/volto/config';
import { DownloadsBlockView, DownloadsBlockEdit } from './components';
import heroSVG from '@plone/volto/icons/hero.svg';

const applyConfig = (config) => {
  config.blocks.blocksConfig.downloads = {
    id: 'downloads',
    title: 'Downloads',
    icon: heroSVG,
    group: 'media',
    view: DownloadsBlockView,
    edit: DownloadsBlockEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
  };
  return config;
};

export default applyConfig;
