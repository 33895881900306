import React, { useCallback, useEffect, useState } from 'react';
import { Form, Button, Container, Header, Progress } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { EPICS_PATH_CONTINUE } from 'volto-epics-addon/constants';
import { TASK_UPLOAD_PROMPT } from '@package/constants';
import { completeTask, useTask } from 'volto-epics-addon/helpers';
import UploadStepsContainer from '@package/components/theme/FileUpload/UploadStepsContainer';
import openIconSVG from '@plone/volto/icons/upload.svg';
import fileIconSVG from '@plone/volto/icons/file.svg';
import { Icon } from '@plone/volto/components';
import loadable from '@loadable/component';
import cx from 'classnames';
import { defineMessages, useIntl } from 'react-intl';
import {
  MEDIA_FILE_AUDIO_TYPES,
  MEDIA_FILE_VIDEO_TYPES,
} from '@package/constants/MediaFileMimeTypes';
import Helmet from '@plone/volto/helpers/Helmet/Helmet';

const Dropzone = loadable(() => import('react-dropzone'));

const messages = defineMessages({
  rejectedFile: {
    id: 'File type cannot be accepted, please drop another file',
    defaultMessage: 'File type cannot be accepted, please drop another file',
  },
  editFile: {
    id: 'Drop file here to replace the existing file',
    defaultMessage: 'Drop file here to replace the existing file',
  },
  fileDrag: {
    id: 'Drop file here to upload a new file',
    defaultMessage: 'Drop file here to upload a new file',
  },
  replaceFile: {
    id: 'Replace existing file',
    defaultMessage: 'Replace existing file',
  },
  addNewFile: {
    id: 'Choose a file',
    defaultMessage: 'Choose a file',
  },
});
const Page = () => {
  const history = useHistory();
  const task = useTask(TASK_UPLOAD_PROMPT);
  const token = useSelector((state) => state.userSession.token);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [progress, setProgress] = useState(null);
  const [rejected, setRejected] = useState(null);
  const intl = useIntl();

  useEffect(() => {
    setIsAuthenticated(!!token);
    setIsVisible(true);
  }, [token]);

  useEffect(() => {
    if (task?.completed === true) {
      history.push(EPICS_PATH_CONTINUE);
    }
  }, [task, history]);

  const onDrop = useCallback(
    (files) => {
      setRejected(false);
      setSelectedFile(files[0]);
    },
    [setSelectedFile],
  );

  const onSubmit = async (ev) => {
    if (!token) return;

    ev.nativeEvent.preventDefault();

    try {
      // Stop submission if no file selected
      if (selectedFile == null) {
        // eslint-disable-next-line no-alert
        alert('Choose a file to upload first.');
        return;
      }

      setProgress(null);

      // Get the presigned upload url from camunda variables
      const presignedUploadUrl = task?.variables?.presignedUploadUrl;

      // Do this the old xhr way,
      // to make it possible to
      // do progress checking
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', presignedUploadUrl, true);

      xhr.upload.onprogress = (ev) => {
        if (ev.lengthComputable) {
          const val = Math.round((ev.loaded / ev.total) * 100);
          setProgress({ percentage: val });
        }
      };

      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
          // If upload was successful, complete the task.
          const fname = selectedFile.name;
          completeTask(TASK_UPLOAD_PROMPT, { filename: fname });
        }
      };
      xhr.send(selectedFile);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const onDropRejected = (fileRejections) => {
    if (fileRejections) {
      setRejected(true);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Media File Upload</title>
      </Helmet>
      {isVisible ? (
        isAuthenticated ? (
          <Container className="file-upload-prompt-container">
            <UploadStepsContainer currentStep={1} totalSteps={4} />
            <Header
              style={{
                margin: '1em auto',
                display: 'block',
                textAlign: 'center',
              }}
            >
              Media upload
            </Header>
            <Form onSubmit={onSubmit}>
              <Form.Field>
                <Dropzone
                  onDrop={onDrop}
                  multiple={false}
                  accept={MEDIA_FILE_AUDIO_TYPES.concat(MEDIA_FILE_VIDEO_TYPES)}
                  onDropRejected={onDropRejected}
                >
                  {({ getRootProps, getInputProps, isDragActive }) => (
                    <div className="media-file-dropzone" {...getRootProps()}>
                      <div
                        className={cx('dropzone-placeholder', {
                          active: isDragActive,
                          filled: selectedFile && !progress,
                          rejected: rejected && !isDragActive,
                        })}
                      >
                        <div>
                          <div
                            className="ui icon"
                            style={{ display: 'block', textAlign: 'center' }}
                          >
                            <Icon name={openIconSVG} size="3.8em" />
                          </div>
                          {selectedFile && !isDragActive && (
                            <div>
                              <div className="ui icon media-file-label">
                                <Icon name={fileIconSVG} size="1.8em" />
                                {selectedFile.name}
                              </div>
                              {!progress && (
                                <p className="dropzone-text">
                                  {intl.formatMessage(messages.editFile)}
                                </p>
                              )}
                            </div>
                          )}
                          {((!selectedFile && !rejected) || isDragActive) && (
                            <p className="dropzone-text">
                              {intl.formatMessage(messages.fileDrag)}
                            </p>
                          )}
                          {rejected && !isDragActive && !selectedFile && (
                            <p className="dropzone-text">
                              {intl.formatMessage(messages.rejectedFile)}
                            </p>
                          )}
                          <label
                            className={cx('label-media-file-input', {
                              active: progress,
                            })}
                            id="field-media-upload-label"
                          >
                            {selectedFile && !progress ? (
                              intl.formatMessage(messages.replaceFile)
                            ) : selectedFile && progress ? (
                              <Progress
                                style={{ margin: 0 }}
                                percent={progress['percentage']}
                                progress
                                active
                              />
                            ) : (
                              intl.formatMessage(messages.addNewFile)
                            )}
                          </label>
                          <input
                            {...getInputProps({
                              type: 'file',
                              style: { display: 'none' },
                            })}
                            id={`field-media-upload`}
                            name="input-media-upload"
                            type="file"
                            aria-labelledby="field-media-upload-label"
                            onChange={(e) => setSelectedFile(e.target.files[0])}
                            disabled={progress}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </Form.Field>
              {selectedFile && !progress ? (
                <Button
                  style={{ marginTop: '1em' }}
                  floated="right"
                  type="submit"
                >
                  Upload
                </Button>
              ) : null}
            </Form>
          </Container>
        ) : (
          <Container className="file-upload-prompt-container">
            <Header> You must log in first. </Header>
          </Container>
        )
      ) : (
        <Container className="file-upload-prompt-container"></Container>
      )}
      ;
    </div>
  );
};

export default Page;
