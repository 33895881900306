import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, Header } from 'semantic-ui-react';
import {
  selectFacetSchemaEnhancer,
  selectFacetStateToValue,
  selectFacetValueToQuery,
} from '@plone/volto/components/manage/Blocks/Search/components/base';
import Icon from '@plone/volto/components/theme/Icon/Icon';
import cx from 'classnames';
import rightArrow from '@plone/volto/icons/right-key.svg';
import downArrow from '@plone/volto/icons/down-key.svg';

function treeFromChoices(choices, value) {
  var tree = {
    children: {},
    checked: false,
  };

  for (let choice of choices) {
    let label = choice.label.replace(/^\/+|\/+$/g, ''); // trim leading and trailing slashes
    let path = label.split(/(?<!\/)\/(?!\/)/); // split by single slash, but ignore double or more
    let obj = tree;
    let checked = value.some((v) => v.value === choice.value);
    for (let p of path) {
      if (!(p in obj.children)) {
        obj.children[p] = {
          children: {},
          checked: false,
        };
      }
      obj = obj.children[p];
      // Set parent as checked if child is checked since value does not contain parents if there is no content tagged with parent path
      // E.g. tag path/parent/child exists, but path/parent does not.
      if (checked) {
        obj.checked = true;
      }
    }
    obj.value = choice;
  }
  return tree;
}

function valueFromTree(tree) {
  var selected = [];
  const inner = (tree, layer) => {
    let s = [];
    if (tree.checked || layer === 0) {
      if ('value' in tree) {
        s.push(tree.value.value);
      }
      for (let child in tree.children) {
        s = s.concat(inner(tree.children[child], layer + 1));
      }
    }
    return s;
  };
  return selected.concat(inner(tree, 0));
}

const TreeBranch = (props) => {
  const { label, tree, path, onTreeChange } = props;
  if (Object.keys(tree).length === 0) {
    return <></>;
  }

  if (Object.keys(tree.children).length === 0) {
    return (
      <div className="tree-branch-header">
        <Checkbox
          label={label}
          checked={tree.checked}
          onChange={(e, { checked }) => {
            let t = { ...tree };
            t.checked = checked;
            onTreeChange(t, true);
          }}
        />
      </div>
    );
  }

  var branches = [];
  for (let child in tree.children) {
    branches.push(
      <TreeBranch
        label={child}
        tree={tree.children[child]}
        path={path + '/' + child}
        onTreeChange={(new_tree, queryUpdated) => {
          let t = { ...tree };
          t.children[child] = new_tree;
          onTreeChange(t, queryUpdated);
        }}
        key={path + '/' + child}
      />,
    );
  }
  if (path === '') {
    return <>{branches}</>;
  }
  return (
    <div>
      <div className="tree-branch-header">
        <Icon name={tree.checked ? rightArrow : downArrow} size="17px" />
        <Checkbox
          label={label}
          onChange={(e, { checked }) => {
            let t = { ...tree };
            t.checked = checked;
            function disableBranch(tree) {
              tree.checked = false;
              for (let child in tree.children) {
                disableBranch(tree.children[child]);
              }
            }
            if (!checked) disableBranch(t);
            onTreeChange(t, 'value' in tree || !checked);
          }}
          checked={tree.checked}
        />
      </div>
      <div className={cx('tree-branch', tree.checked ? 'open' : 'closed')}>
        {branches}
      </div>
    </div>
  );
};

const TreeFacet = (props) => {
  const { facet, choices, onChange, value } = props;
  const [tree, setTree] = useState({});
  const isInitialized = useRef(false);

  useEffect(() => {
    if (!isInitialized.current) {
      setTree(treeFromChoices(choices, value));
      isInitialized.current = true;
    }
  }, [choices, value]);

  return (
    <div className="tree-facet">
      <Header as="h4">{facet.title ?? facet?.field?.label}</Header>
      <div className="entries">
        <TreeBranch
          tree={tree}
          label=""
          path=""
          onTreeChange={(new_tree, queryUpdated) => {
            setTree(new_tree);
            if (queryUpdated) {
              let values = valueFromTree(new_tree);
              onChange(facet.field.value, values);
            }
          }}
        />
      </div>
    </div>
  );
};

TreeFacet.schemaEnhancer = selectFacetSchemaEnhancer;
TreeFacet.stateToValue = selectFacetStateToValue;
TreeFacet.valueToQuery = selectFacetValueToQuery;

export default TreeFacet;
