/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Segment, Grid } from 'semantic-ui-react';
import { defineMessages, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Icon } from '@plone/volto/components';

import {
  PortalFacebookIcon,
  PortalFooterLogo,
  PortalInstagramIcon,
  PortalLinkedInIcon,
  PortalTwitterIcon,
  PortalYoutubeIcon,
} from '@package/icons';

const messages = defineMessages({
  copyright: {
    id: 'Copyright',
    defaultMessage: 'Copyright',
  },
  university: {
    id: 'University of Jyväskylä',
    defaultMessage: 'University of Jyväskylä',
  },
});

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  const currentLang = useSelector((state) => state.intl.locale);

  return (
    <Segment role="contentinfo" vertical>
      <Grid
        textAlign="center"
        verticalAlign="middle"
        columns={3}
        stackable
        className="footer"
      >
        <Grid.Row>
          <Grid.Column>
            <div className="site-brand">
              <a
                tabIndex={-1}
                className="brand-logo"
                aria-label={intl.formatMessage(messages.university)}
                href="https://www.jyu.fi/"
              >
                <Icon name={PortalFooterLogo} />
              </a>
              <a
                className="brand-name"
                aria-label={intl.formatMessage(messages.university)}
                href="https://www.jyu.fi/"
              >
                {intl.formatMessage(messages.university)}
              </a>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className="social-icons">
              <a
                aria-label="Facebook"
                href="https://www.facebook.com/JyvaskylaUniversity"
              >
                <Icon name={PortalFacebookIcon} size="23px" className="test" />
              </a>
              <a
                aria-label="Instagram"
                href="https://www.instagram.com/uniofjyvaskyla"
              >
                <Icon name={PortalInstagramIcon} size="23px" />
              </a>
              <a
                aria-label="LinkedIn"
                href="https://www.linkedin.com/school/166642/"
              >
                <Icon name={PortalLinkedInIcon} size="23px" />
              </a>
              <a aria-label="Twitter" href="https://twitter.com/uniofjyvaskyla">
                <Icon name={PortalTwitterIcon} size="23px" />
              </a>
              <a
                aria-label="Youttube"
                href="https://www.youtube.com/user/JyvaskylaUniversity"
              >
                <Icon name={PortalYoutubeIcon} size="23px" />
              </a>
            </div>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <div className="footer-end-row">
              {currentLang === 'fi' ? (
                <>
                  <div className="privacy-notice">
                    <a
                      aria-label="Tietosuojailmoitus"
                      href="https://www.jyu.fi/fi/yliopisto/tietosuojailmoitus"
                    >
                      Tietosuojailmoitus
                    </a>
                  </div>
                  |
                  <a
                    className="Accessibility-link"
                    href="/fi/saavutettavuusseloste-ilpo-portaali"
                  >
                    Saavutettavuus
                  </a>
                </>
              ) : (
                <>
                  <div className="privacy-notice">
                    <a
                      aria-label="Privacy notice"
                      href="https://www.jyu.fi/en/university/privacy-notice"
                    >
                      Privacy notice
                    </a>
                  </div>
                  |
                  <a
                    className="Accessibility-link"
                    href="/accessibility-research-video"
                  >
                    Accessibility
                  </a>
                </>
              )}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
